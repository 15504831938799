import styled from 'styled-components';

export const LandingSobre = styled.div `
    font-family: Legacy;
    width: 100vw;
    height: 80vh;
    padding: 2% 0 2% 0;
    background-color: #357960;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;

    h2{
        font-size: 38px;
    }

    

    
   


`;